import React, { useContext } from "react"
import { PageWrapper } from "components/PageWrapper"
import DropDown from "./DropDown"
import { Icon } from "utils/getIcon"
import { EmailForm } from "../EmailForm"
import { PopUpContext } from "context/PopUpContext.js"

import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"

import {
  TopText,
  ActionPlanMain,
  ActionButton,
  WarningBox,
  BottomGrid,
  ThanksSection,
  FeedbackBubble,
  FeedbackButton,
} from "./style"

const GET_PLANS = gql`
  query($id: String!) {
    actionPlans(
      first: 100
      where: {
        metaQuery: { metaArray: { key: "type", value: $id, compare: EQUAL_TO } }
      }
    ) {
      nodes {
        title
        data {
          section
          type
          content
        }
        slug
        title
      }
    }
  }
`
// emotional-support : Emotional support
// getting-help-for-your-partner : Getting help for your partner
// health-well-being : Health & well-being
// legal-options : Legal options
// protecting-yourself-online : Protecting yourself online
// sexual-health-and-pregnancy : Sexual health and pregnancy
// staying-safe : Staying safe
// taking-care-of-yourself : Taking care of yourself
// talk-to-someone : Talk to someone
// what-to-do-in-an-emergency : What to do in an emergency

const ActionPlanScreen = ({ id }) => {
  const { togglePopUp } = useContext(PopUpContext)

  const ipv = id === "2"

  const { loading, data } = useQuery(GET_PLANS, {
    variables: { id: id },
  })

  if (loading) return "Loading..."

  const emotionalData = data.actionPlans.nodes.filter(function(el) {
    return el.data.section === "emotional-support"
  })

  const gettingHelpData = data.actionPlans.nodes.filter(function(el) {
    return el.data.section === "getting-help-for-your-partner"
  })

  const healthData = data.actionPlans.nodes.filter(function(el) {
    return el.data.section === "health-well-being"
  })

  const legalData = data.actionPlans.nodes.filter(function(el) {
    return el.data.section === "legal-options"
  })

  const protectData = data.actionPlans.nodes.filter(function(el) {
    return el.data.section === "protecting-yourself-online"
  })

  const sexualHealthData = data.actionPlans.nodes.filter(function(el) {
    return el.data.section === "sexual-health-and-pregnancy"
  })

  const stayingSafeData = data.actionPlans.nodes.filter(function(el) {
    return el.data.section === "staying-safe"
  })
  const talkData = data.actionPlans.nodes.filter(function(el) {
    return el.data.section === "talk-to-someone"
  })

  const takingCareData = data.actionPlans.nodes.filter(function(el) {
    return el.data.section === "taking-care-of-yourself"
  })

  const whatToDoEmergencyData = data.actionPlans.nodes.filter(function(el) {
    return el.data.section === "what-to-do-in-an-emergency"
  })

  const noneData = data.actionPlans.nodes.filter(function(el) {
    return el.data.section === "none"
  })

  return (
    <PageWrapper id="nav" normalScroll>
      <ActionPlanMain>
        <ThanksSection>
          <TopText>
            <h2>Your Action Plan</h2>

            <p>
              The Action Plan contains a list of helpful strategies and
              activities you might like to try and contact details for
              organisations that can provide further assistance. 
            
            </p><p>When you have
              finished looking through the information, please close the browser window. We hope the MySafety website has been
              useful to you.
            </p>
           
          </TopText>
          <FeedbackBubble>
            <h3>We'd love your feedback</h3>
            <p>
              As we want to make sure that MySafety is meeting the needs of
              students at The University of Melbourne, we would love it if you
              would take the time to provide some anonymous feedback.
            </p>
            <FeedbackButton
              label="TELL US WHAT YOU THINK"
              fullWidth
              onClick={() =>
                togglePopUp(
                  true,
                  "feelings",
                  null,
                  "Please enter your feedback below:"
                )
              }
            />
          </FeedbackBubble>
        </ThanksSection>

        <DropDown label="Emotional support" sectionData={emotionalData} />
        <DropDown
          label="Getting help for your partner"
          sectionData={gettingHelpData}
        />
        <DropDown label="Health & well-being" sectionData={healthData} />
        <DropDown label="Legal options" sectionData={legalData} />
        <DropDown
          label="Protecting yourself online"
          sectionData={protectData}
        />
        <DropDown
          label="Sexual health & pregnancy"
          sectionData={sexualHealthData}
        />
        <DropDown label="Staying Safe" sectionData={stayingSafeData} />
        <DropDown label="Talk to someone" sectionData={talkData} />
        <DropDown
          label="Taking care of yourself"
          sectionData={takingCareData}
        />
        <DropDown
          label="What to do in an emergency"
          sectionData={whatToDoEmergencyData}
        />
        <DropDown label="Strategies" sectionData={noneData} />

        {ipv && (
          <ActionButton
            fullWidth
            label={"See more strategies"}
            link={"/action-plan/3"}
          />
        )}

        <BottomGrid ipv={ipv} cols={[1, 2, 2]} gap={[20, 20, 45]}>
          <EmailForm />
          <WarningBox>
            <Icon className="error" name="warning" />
            <h3>Please don’t ignore these recommendations.</h3>
            <p>
              Your safety and wellbeing are extremely important. Feel free to
              come back to this site at anytime to help figure out what's going
              on in your relationship and find support.
            </p>
          </WarningBox>
        </BottomGrid>
      </ActionPlanMain>
    </PageWrapper>
  )
}

export default ActionPlanScreen
